var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('setting-layout',{attrs:{"title":_vm.$t('pages.user.setting.creator.subscriptions.title')}},[_c('v-container',{staticClass:"pb-0 overflow-x-hidden"},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"relative",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold text-md-body-2 pl-4 mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "pages.user.setting.creator.subscriptions.subscription_price" ))+" ")]),_c('text-field',{attrs:{"error-messages":_vm.errors.price,"placeholder":"4.99-49.99","prepend":_vm.currency},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t( "pages.user.setting.creator.subscriptions.per_month" ))+" ")])]},proxy:true}]),model:{value:(_vm.model.price),callback:function ($$v) {_vm.$set(_vm.model, "price", $$v)},expression:"model.price"}})],1)],1),_c('v-row',{staticClass:"pa-4 mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12 px-0 py-0"}},[_c('v-textarea',{staticStyle:{"border-radius":"8px"},attrs:{"color":"rgba(237,237,237, .1)","background-color":"rgba(237,237,237, .1)","no-resize":"","solo":"","placeholder":_vm.$t(
                            'pages.user.setting.creator.subscriptions.welcome_message'
                        ),"rows":"2","hint":("* " + (_vm.$t(
                        'pages.user.setting.creator.subscriptions.welcome_message_rule'
                    ))),"persistent-hint":"","error-messages":_vm.errors.welcome_message},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},model:{value:(_vm.model.welcome_message),callback:function ($$v) {_vm.$set(_vm.model, "welcome_message", $$v)},expression:"model.welcome_message"}})],1)],1),_c('v-row',{staticClass:"mt-6 px-4",attrs:{"align":"center","no-gutters":""}},[_c('v-row',{staticClass:"mb-4",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('div',{staticClass:"font-weight-bold text-md-body-2",on:{"click":function($event){_vm.showBundles = !_vm.showBundles}}},[_vm._v(" Subscription bundles ")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticStyle:{"height":"24px","transition":"0.3s"},style:(_vm.showBundles ? 'transform: scaleY(-1);' : '')},[_c('IconMore')],1)])],1),(_vm.showBundles)?_vm._l((_vm.model.bundles),function(bundle,bundleIndex){return _c('v-row',{key:bundle.period,staticClass:"py-2 px-4 mt-2",staticStyle:{"background":"rgba(237, 237, 237, 0.1)","border-radius":"8px","line-height":"52px"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%"}},[_c('v-checkbox',{attrs:{"value":bundle.isEnabled,"color":"primary"},model:{value:(bundle.isEnabled),callback:function ($$v) {_vm.$set(bundle, "isEnabled", $$v)},expression:"bundle.isEnabled"}}),_c('div',{staticClass:"text-no-wrap",staticStyle:{"width":"100%"}},[_c('b',[_vm._v(_vm._s(bundle.period)+" months")]),_vm._v(" for "),_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.currency)+_vm._s(_vm.model.price * bundle.period - (_vm.model.price * bundle.period * bundle.discount) / 100)+" ")])]),_c('text-field',{staticClass:"ml-2",staticStyle:{"max-width":"120px","min-width":"120px","width":"120px","background":"transparent !important"},attrs:{"placeholder":"0-50","error-messages":_vm.invalidBundleIndex === bundleIndex
                                    ? ['From 0 to 50 %']
                                    : [],"type":"number","prepend":"%"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"opacity":"0.5"}},[_vm._v("OFF")])]},proxy:true}],null,true),model:{value:(bundle.discount),callback:function ($$v) {_vm.$set(bundle, "discount", $$v)},expression:"bundle.discount"}})],1)])}):_vm._e()],2),(_vm.model.benefits_list)?_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","no-gutters":""}},[_c('v-row',{staticClass:"mb-4 pr-2",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('div',{staticClass:"font-weight-bold text-md-body-2 pl-4"},[_vm._v(" Subscription benefits ")])]),_c('v-col',{attrs:{"cols":"1"}},[(
                            _vm.model.benefits_list.length < 10 &&
                            _vm.model.benefits_list.every(
                                function (str) { return str.trim() !== ''; }
                            )
                        )?_c('button',{on:{"click":function($event){return _vm.model.benefits_list.push('')}}},[_c('app-icon',{attrs:{"icon":"plus","size":"16"}})],1):_vm._e()])],1),_c('div',{staticClass:"w-full mb-2 grey--text pl-4 delete-icon-background",staticStyle:{"background":"rgba(237, 237, 237, 0.1)","border-radius":"8px","line-height":"52px"}},[_vm._v(" Support the Noodzler ")]),_c('div',{staticClass:"w-full mb-2 grey--text pl-4 delete-icon-background",staticStyle:{"background":"rgba(237, 237, 237, 0.1)","border-radius":"8px","line-height":"52px"}},[_vm._v(" Unlock all the content ")]),_c('div',{staticClass:"w-full grey--text pl-4 delete-icon-background",staticStyle:{"background":"rgba(237, 237, 237, 0.1)","border-radius":"8px","line-height":"52px"}},[_vm._v(" Unlimited Direct Messages ")]),(_vm.model.benefits_list.length)?_c('hr',{staticClass:"my-4 w-full",staticStyle:{"opacity":"0.25","height":"1px"}}):_vm._e(),_vm._l((_vm.model.benefits_list),function(item,index){return _c('text-field',{key:index,staticClass:"mb-2",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('button',{staticStyle:{"opacity":"0.5"},on:{"click":function($event){_vm.model.benefits_list =
                                _vm.model.benefits_list.filter(
                                    function (benefit, benefitIndex) { return benefitIndex !== index; }
                                )}}},[_c('app-icon',{attrs:{"icon":"delete","size":"20"}})],1)]},proxy:true}],null,true),model:{value:(_vm.model.benefits_list[index]),callback:function ($$v) {_vm.$set(_vm.model.benefits_list, index, $$v)},expression:"model.benefits_list[index]"}})})],2):_vm._e(),_c('v-row',{staticClass:"pa-4 mt-2",attrs:{"align":"center","justify":"center"}},[_c('gradient-button',{attrs:{"block":"","loading":_vm.$store.state.buttonsLoading[
                        'setting/subscriptionButton'
                    ]},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.subscriptions.save"))+" ")])],1)],1),_c('v-container',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.subscriptions.subscribers"))+" ("+_vm._s(_vm.subscriberSubscriptions.length)+") "),_vm._l((_vm.subscriberSubscriptions),function(subscription,i){return _c('v-row',{key:i,staticClass:"mt-3"},[_c('div',{staticClass:"subscriber pl-4 pb-4"},[_c('v-row',{staticClass:"subscriber-title"},[_c('v-col',{attrs:{"cols":"7","sm":"4","md":"6"}},[_c('router-link',{attrs:{"to":{
                                name: 'otherUser.profile.viewSlug',
                                params: {
                                    slug: subscription.subscriber.slug,
                                    from: _vm.$route.name
                                },
                            }}},[_vm._v(" "+_vm._s(subscription.subscriber.name)+" ")])],1),_c('v-col',{staticClass:"price d-flex justify-center",attrs:{"cols":"5","sm":"8","md":"6"}},[_vm._v(_vm._s(subscription.fixed_price)+" "+_vm._s(_vm.currency)+" ")])],1),(subscription.created_at)?_c('v-row',[_c('span',{staticClass:"date-text pl-4"},[_vm._v("Start date:")]),_c('span',{staticClass:"date-value pl-3"},[_vm._v(" "+_vm._s(subscription.created_at))])]):_vm._e(),(subscription.renewal_at)?_c('v-row',[_c('span',{staticClass:"date-text pl-4"},[_vm._v("Renewal date:")]),_c('span',{staticClass:"date-value pl-3"},[_vm._v(" "+_vm._s(subscription.renewal_at)+" ")])]):_vm._e()],1)])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }