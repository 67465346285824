<template>
    <app-transition>
        <Dialog
            class="SetSubscriptionPriceError"
            :only-text="true"
            @close="handleClose"
        >
            <v-row text-center="">
                <v-col>
                  {{ $t('dialogs.set_subscription_price_error') }}{{dmPrice}}$
                </v-col>
            </v-row>

            <router-link
                :to="{name: 'user.setting.creator.messages'}"
                style="text-transform: capitalize; text-decoration: underline;"
                class="mb-2"
            >
                {{ $t('dialogs.messages_page') }}
            </router-link>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "SetSubscriptionPriceError",
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
    components: {
        Dialog,
    },
    props: {
        dmPrice: {type: Number, default: null}
    }
}
</script>
